<template>
  <li class="nav-item d-flex border-right pr-75 mr-75">
    <a class="nav-link text-danger d-lg-flex align-items-center py-75 d-none">
        <feather-icon
          size="18"
          icon="ClockIcon"
        />
        <span class="dt-text">{{currentTime}} WIB</span>
    </a>
    <a class="nav-link text-danger d-flex align-items-center py-75">
        <feather-icon
          size="18"
          icon="CalendarIcon"
        />
        <span class="dt-text">{{currentDate}}</span>
    </a>
  </li>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BNavItem,
  },
  data() {
    return {
      currentTime: '',
      currentDate: '',
    }
  },
  created() {
      moment.locale('id')

      setInterval(this.getNow, 1000)

      this.currentDate = moment().format('dddd, DD MMM YYYY')
  },
  methods: {
    getNow() {
      this.currentTime = moment().format('HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
span.dt-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    color: #f20303;

    margin-left: 6px;
}
</style>
